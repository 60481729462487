window.addEventListener('DOMContentLoaded', (event) => {

  // メインスライダー
  const mainSlider = new Swiper('.top-visual-swiper', {
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 2000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });

  // Slider Menu
  const speed = 5000;
  const SubSlider = new Swiper('.slidermenu-swiper', {
    loop: true,
    slidesPerView: 'auto',
    speed: speed,
    allowTouchMove: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  });
  
  let getTranslate;
  const activeElement = document.querySelector('.slidermenu-swiper .swiper-slide-active');

  document.querySelectorAll('.slidermenu-swiper').forEach(function(e){

    e.addEventListener('mouseover', function () {
      getTranslate = SubSlider.getTranslate();
      SubSlider.setTranslate(getTranslate);
      SubSlider.setTransition(0);
    });

    e.addEventListener('mouseout', function () {
      getTranslate = SubSlider.getTranslate();

      let getSlideWidthMgLeft = activeElement.style.marginLeft;
      if (getSlideWidthMgLeft) {
        getSlideWidthMgLeft = parseFloat(getSlideWidthMgLeft);
      } else {
        getSlideWidthMgLeft = 0;
      }

      let getSlideWidthMgRight = activeElement.style.marginRight;
      if (getSlideWidthMgRight) {
        getSlideWidthMgRight = parseFloat(getSlideWidthMgRight);
      } else {
        getSlideWidthMgRight = 0;
      }

      let getSlideWidth = activeElement.offsetWidth;

      let getTotalSlideWidth = getSlideWidthMgLeft + getSlideWidthMgRight + getSlideWidth;
      let diff = - getTotalSlideWidth - (getTranslate % getTotalSlideWidth);
      let diffTime = diff / -getSlideWidth;
      SubSlider.setTranslate(getTranslate + diff);
      SubSlider.setTransition(speed * diffTime);
    });
  });
  
  SubSlider.on('slideChangeTransitionEnd', function(swiper){

    swiper.autoplay.start();

  });

  document.querySelector('.swiper-button-prev').addEventListener('click', function(e){

    SubSlider.slideTo(SubSlider.activeIndex - 2, 1000);

  });

  document.querySelector('.swiper-button-next').addEventListener('click', function(e){
    
    SubSlider.slideTo(SubSlider.activeIndex + 1, 1000);
  });


});
